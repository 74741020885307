#projGallery{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    margin: 5rem 0;

}

.singleProjSlide{
    display: flex;
    justify-content: center;
    
    height: 400px;
    width: 25vw;
    aspect-ratio: 1.75;
    position: relative;

    margin-bottom: 5rem;
    box-shadow:0 0 20px -10px _palette(shadow);

    .icon{
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;

        position: relative;
        top: -25px;
        width: 70px;
        aspect-ratio: 1;
        background-color: _palette(bg);
        border-radius: 50%;

        span{
            z-index: 10;
        }

        .iconBg1{
            position: absolute;
            width: 100%;
            aspect-ratio: 1;
            border-radius: 50%;
            background-image: linear-gradient(-45deg, _palette(mainBlue) 0%,  _palette(brightGreen) 70%);
            z-index: 1;
            opacity: 0.4;
        }

        .iconBg2{
            position: absolute;
            top: -20px;
            left: 5px;
            width: 100%;
            aspect-ratio: 1;
            border-radius: 50%;
            background-image: linear-gradient(-45deg, _palette(lighterBlue) 0%,  _palette(mainGreen) 70%);
            z-index: 0;
            opacity: 0.5;
        }
    }
}



//
//Description
//

.slideContent{
    position:absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: _palette(main);

    transition: opacity 0.25s;
    opacity: 1;
    h2{
        text-align: center;
        padding: 2rem 1rem 0;
        font-weight: _font(weight-bold);
    }
    h3{
        padding: 1rem 1.5rem 0;
    }
}

.blankProj{
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2{
        margin-top: -3rem;
        color: #bbbbb7;
    }
}

//
//Slide button
//

#slideButton{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    
    bottom: 2rem;
    height: 15%;
    width: 60%;
    z-index: 100;
    a{
        text-align: center;
        line-height: 50px;
        transition: all .5s ease-in-out;
        border: none;
        height: 100%;
        width: 100%;
        border-radius: 50px;
        font-weight: _font(weight-bold);
        background-image: linear-gradient(90deg, _palette(mainGreen), _palette(lighterBlue) 70%, _palette(mainBlue) 99%);
        background-size: 300% 100%;
        &:hover{
            background-position: 100% 0;
            transition: all .5s ease-in-out;
        }
    }
}

//
//Media Queries
//

@media (max-width:_breakpoints(laptopS)) {
    #projGallery{
        flex-direction: column;

        .singleProjSlide{
            width: 60vw;
            margin-bottom: 5rem;
            box-shadow:0 0 20px -7px _palette(shadow);
        }
    }
    .blank{
        display: none !important;
    }
};

@media (max-width:_breakpoints(mobileL)) {

    #projTitle{
        height: 3rem;
        top: -2.9rem;
        h2{
            font-size: 1rem;
        }
    }
    .singleProjSlide{
        width: 70vw !important;
    }
};