.circleDiv{
    position: absolute;
    z-index: 1;
    opacity: 0.5;
    overflow: hidden;
}

.circle1{ 
    top:-6rem;
    left: -3rem;
}

.circle2{ 
    top: 30vh;
    right: 1rem;
}

.circle3{ 
    top: 110vh;
    left: 0rem;
}

.circle4{ 
    top: 140vh;
    right: -3vw;
}

.circle5{ 
    bottom: -100vh;
    right: -3.5vw;
}

.circle6{ 
    bottom: -180vh;
    right: 30vw;
}

.circle7{ 
    bottom: -120vh;
    left: -3vw;
}
