#hobbies{
    padding: 1em 3em 1em;
    background: _palette(main);

    h3 {
        ul{
            display: flex;
            align-items: center;
            justify-content: space-evenly;  
            
            border: 1px solid _palette(baseText);

            li{
                font-weight: _font(weight-bold);
                display: inline-block;
                padding: 0.25rem 0.5rem;
            }
        }

    }
    #subtitle{
        margin-bottom: 4rem;
    }
}

@media (max-width:_breakpoints(tablet)) {
    #hobbies{
        padding: 1em 2em 1em;
        h3 ul{
            li{
                font-size: .8rem;
            }
        }
    }
};

@media (max-width:_breakpoints(mobileL)) {
    #hobbies{
        padding: 5em 2em 1em;
        h3 ul{
            display: inline-block;
            li{
                font-size: .8rem;
            }
        }
    }
};

//
//Cranes
//

.Crane1{
    position: absolute;
    right: 0;
}
