#contact{
    padding: 5em 3em 1em;
    background: _palette(main);

    a{
        display: inline-block;
    }

    h1{
        position: relative;
        &::before {
            content: '';
            position: absolute;
            bottom: -5px; left: 0;
            width: 80px;
            height: 5px;
            background-color: _palette(mainGreen);
          }
    }
}

.icon{
    height: 2rem;
    padding: 0rem 0.5rem;
}

@media (max-width:_breakpoints(tablet)) {
    #contact{
        padding: 5em 2em 1em;
    }
}