#projects{
    position: relative;
    padding: 1em 3em 1em;
    margin: 0rem 0 -0.5rem;
    background: _palette(bg);
    
}

.waveProjects {
    path{
        fill: _palette(main);
    }
}

@media (max-width:_breakpoints(tablet)) {
    #projects{
        padding: 1em 2em 1em;
        margin: 0rem 0 -0.5rem;
    }
}

@media (max-width:_breakpoints(mobileL)) {
    #projects{
        h1{
            font-size: 1.75rem;
        }
    }
}

#block{
    height: 20vh;
    width: 100%;
    background-image: linear-gradient(-20deg, _palette(mainBlue), _palette(mainGreen));
}

//
//Circles
//
