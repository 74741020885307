//
//Text Styles
//

h1, h2, h3, h4, a{
    font-family: _font(family);
    font-weight: _font(weight);
    color: _palette(baseText);
}

h1{
    font-size: 2rem;
}

h2{
    font-size: 1.2rem;
}

h3{
    font-size: 1rem;
}

h4{
    font-size: .8rem;
}

a{
    font-size: 1rem;
    text-decoration: none;
    border-bottom: none;
}

#subtitle{
    margin: 0 0 7rem;
    font-size: 1.75rem;
    text-align: center;
    position: relative;
    z-index: 1;
}
#title{
    text-align: center;
    text-decoration: underline _palette(mainGreen) 5px;
    text-decoration-skip-ink: none;
    margin: 5rem 0 1rem;
}

.bold{
    font-weight: _font(weight-bold);
}

@media (max-width:_breakpoints(mobileL)) {
    h1{
        font-size: 2.5rem;
    }
    h3{
        font-size: 0.8rem;
    }
}

//
//List stuff
//

ul{
    margin: 0;
    padding: 0;
}

body{
    background: _palette(main);
    line-height: 1.5rem;
    max-width: 100%;
    h1{
        line-height: 2.5rem;
    }
}

//
//Misc
//

html{
    scroll-behavior: smooth;
}

.wave{
    width: 100%;
    height: 100%;
    path{
        stroke: none;
    }
}

#app{
    perspective: 1px;
}

//prevent highlighting image
img {
    @include no-select;
}
