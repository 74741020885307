#about {
    padding: 1em 3em 1em;
    margin-bottom: -0.5rem;
    background: _palette(main);
    overflow: auto;

    h1{
        display: inline-block;
        z-index: 1;
        position: relative;
        span{
            text-decoration: underline _palette(mainGreen) 5px;
        }
    }
    h2{
        margin-top: 2rem;
        z-index: 1;
        position: relative;
    }
}

#imgMeContainer{
    float:right;

    position: relative;
    width: 250px;
    height: 250px;
    margin: 0 0 1rem 1rem;
}

#imgMeBg{
    width: 100%;
    height: 100%;
    position: absolute;
    top:1rem;
    left: 1rem;
    background-image: linear-gradient(-45deg, _palette(mainBlue) 0%,  _palette(mainGreen) 70%);
    z-index: 2;
}

#meImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    
    position: relative;
    z-index: 10;
}

@media (max-width:_breakpoints(tablet)) {
    #about{
        padding: 1em 2em 1em;
        h1{
            font-size: 2.25rem;
        }
                
        h2{
            margin-top: 0rem !important;
            font-size: 1.0rem;
        }
    }
    #imgMeContainer{
        width: 170px;
        height: 170px;
    }
}

@media (max-width:_breakpoints(mobileL)) {
    #imgMeContainer{
        display: block;
        width: 150px;
        height: 150px;
        margin-left: auto;
        margin-right: auto;
        float: none;
    }
    #about{
        h1{
            font-size: 1.75rem;
            &::before{
                width: 100px !important;
            }
        }

    }   
}

//
//Circle
//
