.waveDiv{
    margin-top: 3rem;
    margin-bottom: -1rem;
}

#wave{
    transform:rotate(0deg); 
    transition: 0.3s;
}

#path1{
    transform:translate(0, 0px); 
    opacity:1;
}

#path2{
    transform:translate(0, 50px); 
    opacity:1;
}

#path3{
    transform:translate(0, 100px); 
    opacity:1;
}